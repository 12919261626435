<template lang="html">

  <section class="blog">
    <div class="container grid-3" v-if="store.getEntries(this.page)">
      <router-link :to="{
        name: 'blog-page',
        params: {
          lang:$i18n.locale,
          slug: entryData.slug,
        }
      }" class="grid entry-view" v-for="(entryData, key ) in store.getEntries(this.page)" :key="key">
        <div class="img-container" :data-content="$t('web.moreinfo')">
          <img :src="entryData.image.url" alt="imagen de blog">
        </div>
        <p class="title-semi-medium uppercase">

          {{ entryData.name }}
        </p>
        <p class="date">{{ currentDateTime(entryData.publish_date) }}</p>
        <p class="description">
          {{ entryData.header }}
        </p>
      </router-link>
      <paginator :pageActual="page" :totalItems="NewsMaxItems" @clicked="changePages"></paginator>
    </div>
    <loading :active='isLoading' :loader='"none"' />

  </section>

</template>

<script lang="js">
import Loading from 'vue-loading-overlay';

import moment from 'moment'
import { contentStore } from '@/stores/contents'
import paginator from '@/components/paginator.vue'
const store = contentStore();
export default {
  name: 'blog',
  props: [],

  components: {
    paginator,
    Loading
  },
  data() {
    return {
      isLoading: true,
    }
  }, 
  setup() {
    return {
      store,
      getEntries: store.getEntries,
      setPageEntries: store.setPageEntries,
      getEntriesPage: store.getEntriesPage
    }
  },
  computed: {
    page() {
      return store.getEntriesPage
    },
    NewsMaxItems() {
      return store.getPagesEntries();
    },
  },
  mounted() {
    this.changePages(store.getEntriesPage);
  },
  methods: {
    currentDateTime(date) {
      return moment(date).locale('en').format('LL')
    },

    changePages(item) {
      let t = this;
      this.isLoading = true;
      
      this.setPageEntries(item);
      let p = store.loadEntries(
        {
          page: this.page,
        }
      );
      Promise.resolve(p).then(function () {
        t.isLoading = false;
      });
    }
  },
  watch: {
      "store.lang": function (newLang) {
        this.changePages(1);
      }
    }
  }
</script>

<style scoped lang="scss">
.blog {}

.vl-overlay .vl-background {
  background: url(/img/loading.gif) 50% 50% no-repeat rgb(249, 249, 249) !important;
}
</style>